<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 32">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M24.5 8h-20a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1Zm1 6 6-4v12l-6-4" />
  </svg>
</template>

<script>
export default {
  name: 'SvgVideoCall'
};
</script>
