<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 32">
    <g stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
      <path d="m11.5 17 3 3 7-7" />
      <path d="M16.5 28c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12Z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgCheckCircle'
};
</script>
