<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
    <g stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
      <path d="M19 14h5m-5 4h5m3-12H5a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Z" />
      <path d="M12 18a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-4 3c.444-1.725 2.136-3 4-3 1.864 0 3.557 1.274 4 3" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgIdCard'
};
</script>
