<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
    <g stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
      <path d="M25 28H7a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h12l7 7v16a1 1 0 0 1-1 1Z" />
      <path d="M19 4v7h7m-13 7 3-3 3 3m-3 5v-8" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgFileUpArrow'
};
</script>
