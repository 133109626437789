<template>
  <component :is="componentName"></component>
</template>

<script>
import SvgAccountSettings from 'src/vue-app/shared/components/svg-icons/svg-account-settings.vue';
import SvgAddInvitee from 'src/vue-app/shared/components/svg-icons/svg-add-invitee.vue';
import SvgAddLink from 'src/vue-app/shared/components/svg-icons/svg-add-link.vue';
import SvgAddItem from 'src/vue-app/shared/components/svg-icons/svg-add-item.vue';
import SvgAddedItemGradient from 'src/vue-app/shared/components/svg-icons/svg-added-item-gradient.vue';
import SvgAddedItem from 'src/vue-app/shared/components/svg-icons/svg-added-item.vue';
import SvgAlert from 'src/vue-app/shared/components/svg-icons/svg-alert.vue';
import SvgAnalytics from 'src/vue-app/shared/components/svg-icons/svg-analytics.vue';
import SvgArchive from 'src/vue-app/shared/components/svg-icons/svg-archive.vue';
import SvgArrowDown from 'src/vue-app/shared/components/svg-icons/svg-arrow-down.vue';
import SvgArrowLeft from 'src/vue-app/shared/components/svg-icons/svg-arrow-left.vue';
import SvgArrowRight from 'src/vue-app/shared/components/svg-icons/svg-arrow-right.vue';
import SvgArrowUp from 'src/vue-app/shared/components/svg-icons/svg-arrow-up.vue';
import SvgAskLawyer from 'src/vue-app/shared/components/svg-icons/svg-ask-lawyer.vue';
import SvgAttachment from 'src/vue-app/shared/components/svg-icons/svg-attachment.vue';
import SvgAward from 'src/vue-app/shared/components/svg-icons/svg-award.vue';
import SvgBriefcase from 'src/vue-app/shared/components/svg-icons/svg-briefcase.vue';
import SvgCalculatorCheck from 'src/vue-app/shared/components/svg-icons/svg-calc-check.vue';
import SvgCalendarCheck from 'src/vue-app/shared/components/svg-icons/svg-calendar-check.vue';
import SvgCalendarGradient from 'src/vue-app/shared/components/svg-icons/svg-calendar-gradient.vue';
import SvgCalendar from 'src/vue-app/shared/components/svg-icons/svg-calendar.vue';
import SvgCaretDown from 'src/vue-app/shared/components/svg-icons/svg-caret-down.vue';
import SvgCaretLeft from 'src/vue-app/shared/components/svg-icons/svg-caret-left.vue';
import SvgCaretRight from 'src/vue-app/shared/components/svg-icons/svg-caret-right.vue';
import SvgCaretUp from 'src/vue-app/shared/components/svg-icons/svg-caret-up.vue';
import SvgChat from 'src/vue-app/shared/components/svg-icons/svg-chat.vue';
import SvgCheck from 'src/vue-app/shared/components/svg-icons/svg-check.vue';
import SvgCheckAndDollar from 'src/vue-app/shared/components/svg-icons/svg-check-and-dollar.vue';
import SvgCheckCircle from 'vue-app/shared/components/svg-icons/svg-check-circle.vue';
import SvgCheckSmall from 'src/vue-app/shared/components/svg-icons/svg-check-small.vue';
import SvgCheckmarkGreen from 'src/vue-app/shared/components/svg-icons/svg-checkmark-green.vue';
import SvgCheckmarkNoCircle from 'src/vue-app/shared/components/svg-icons/svg-checkmark-no-circle.vue';
import SvgCheckmarkWhite from 'src/vue-app/shared/components/svg-icons/svg-checkmark-white.vue';
import SvgCheckmark from 'src/vue-app/shared/components/svg-icons/svg-checkmark.vue';
import SvgCircle from 'src/vue-app/shared/components/svg-icons/svg-circle.vue';
import SvgClients from 'src/vue-app/shared/components/svg-icons/svg-clients.vue';
import SvgClockDue from 'src/vue-app/shared/components/svg-icons/svg-clock-due.vue';
import SvgCopy from 'src/vue-app/shared/components/svg-icons/svg-copy.vue';
import SvgCourt from 'src/vue-app/shared/components/svg-icons/svg-court.vue';
import SvgDefaultProfile from 'src/vue-app/shared/components/svg-icons/svg-default-profile.vue';
import SvgDevices from 'src/vue-app/shared/components/svg-icons/svg-devices.vue';
import SvgDocument from 'src/vue-app/shared/components/svg-icons/svg-document.vue';
import SvgDocuments from 'src/vue-app/shared/components/svg-icons/svg-documents.vue';
import SvgDollar from 'src/vue-app/shared/components/svg-icons/svg-dollar.vue';
import SvgDownload from 'src/vue-app/shared/components/svg-icons/svg-download.vue';
import SvgDragAndDrop from 'src/vue-app/shared/components/svg-icons/svg-drag-and-drop.vue';
import SvgEducation from 'src/vue-app/shared/components/svg-icons/svg-education.vue';
import SvgEnvelope from 'src/vue-app/shared/components/svg-icons/svg-envelope.vue';
import SvgEnvelopeAlt from 'src/vue-app/shared/components/svg-icons/svg-envelope-alt.vue';
import SvgExclamationWhite from 'src/vue-app/shared/components/svg-icons/svg-exclamation-white.vue';
import SvgExclamation from 'src/vue-app/shared/components/svg-icons/svg-exclamation.vue';
import SvgExpand from 'src/vue-app/shared/components/svg-icons/svg-expand.vue';
import SvgExperience from 'src/vue-app/shared/components/svg-icons/svg-experience.vue';
import SvgFeatureLabLogo from 'src/vue-app/shared/components/svg-icons/svg-feature-lab-logo.vue';
import SvgFeedback from 'src/vue-app/shared/components/svg-icons/svg-feedback.vue';
import SvgFileUpArrow from 'vue-app/shared/components/svg-icons/svg-file-up-arrow.vue';
import SvgFirmProfile from 'src/vue-app/shared/components/svg-icons/svg-firm-profile.vue';
import SvgFlyingStar from 'src/vue-app/shared/components/svg-icons/svg-flying-star.vue';
import SvgFormDollar from 'src/vue-app/shared/components/svg-icons/svg-form-dollar.vue';
import SvgFormRadioButton from 'src/vue-app/shared/components/svg-icons/svg-form-radio-button.vue';
import SvgFormText from 'src/vue-app/shared/components/svg-icons/svg-form-text.vue';
import SvgGear from 'src/vue-app/shared/components/svg-icons/svg-gear.vue';
import SvgGlobe from 'src/vue-app/shared/components/svg-icons/svg-globe.vue';
import SvgGoogleDrive from 'src/vue-app/shared/components/svg-icons/svg-google-drive.vue';
import SvgHeadquarters from 'src/vue-app/shared/components/svg-icons/svg-headquarters.vue';
import SvgHome from 'src/vue-app/shared/components/svg-icons/svg-home.vue';
import SvgIdCard from 'vue-app/shared/components/svg-icons/svg-id-card.vue';
import SvgInfo from 'src/vue-app/shared/components/svg-icons/svg-info.vue';
import SvgInviteGradient from 'src/vue-app/shared/components/svg-icons/svg-invite-gradient.vue';
import SvgInvite from 'src/vue-app/shared/components/svg-icons/svg-invite.vue';
import SvgLanguage from 'src/vue-app/shared/components/svg-icons/svg-language.vue';
import SvgLawFirm from 'src/vue-app/shared/components/svg-icons/svg-law-firm.vue';
import SvgLawyerProfile from 'src/vue-app/shared/components/svg-icons/svg-lawyer-profile.vue';
import SvgLinkedin from 'src/vue-app/shared/components/svg-icons/svg-linkedin.vue';
import SvgListBullets from 'src/vue-app/shared/components/svg-icons/svg-list-bullets.vue';
import SvgList from 'src/vue-app/shared/components/svg-icons/svg-list.vue';
import SvgLocation from 'src/vue-app/shared/components/svg-icons/svg-location.vue';
import SvgLockedDevices from 'src/vue-app/shared/components/svg-icons/svg-locked-devices.vue';
import SvgLogOut from 'src/vue-app/shared/components/svg-icons/svg-log-out.vue';
import SvgMarketplaceLogo from 'src/vue-app/shared/components/svg-icons/svg-marketplace-logo.vue';
import SvgMenu from 'src/vue-app/shared/components/svg-icons/svg-menu.vue';
import SvgMessage from 'src/vue-app/shared/components/svg-icons/svg-message.vue';
import SvgMinimize from 'src/vue-app/shared/components/svg-icons/svg-minimize.vue';
import SvgMinusCircle from 'src/vue-app/shared/components/svg-icons/svg-minus-circle.vue';
import SvgMinus from 'src/vue-app/shared/components/svg-icons/svg-minus.vue';
import SvgNewProject from 'src/vue-app/shared/components/svg-icons/svg-new-project.vue';
import SvgNote2 from 'src/vue-app/shared/components/svg-icons/svg-note-2.vue';
import SvgNote from 'src/vue-app/shared/components/svg-icons/svg-note.vue';
import SvgNoteWithDollarSign from 'src/vue-app/shared/components/svg-icons/svg-note-with-dollar-sign.vue';
import SvgNoteWithHashtag from 'src/vue-app/shared/components/svg-icons/svg-note-with-hashtag.vue';
import SvgNotification from 'src/vue-app/shared/components/svg-icons/svg-notification.vue';
import SvgOptions from 'src/vue-app/shared/components/svg-icons/svg-options.vue';
import SvgOrderedList from 'src/vue-app/shared/components/svg-icons/svg-ordered-list.vue';
import SvgPaperPlane from 'src/vue-app/shared/components/svg-icons/svg-paper-plane.vue';
import SvgPencil from 'src/vue-app/shared/components/svg-icons/svg-pencil.vue';
import SvgPhone from 'src/vue-app/shared/components/svg-icons/svg-phone.vue';
import SvgPlusCircle from 'src/vue-app/shared/components/svg-icons/svg-plus-circle.vue';
import SvgPlus from 'src/vue-app/shared/components/svg-icons/svg-plus.vue';
import SvgPresent from 'src/vue-app/shared/components/svg-icons/svg-present.vue';
import SvgPrint from 'src/vue-app/shared/components/svg-icons/svg-print.vue';
import SvgPrioriAi from 'src/vue-app/shared/components/svg-icons/svg-priori-ai.vue';
import SvgPrioriGradient from 'src/vue-app/shared/components/svg-icons/svg-priori-gradient.vue';
import SvgPrioriLogo from 'src/vue-app/shared/components/svg-icons/svg-priori-logo.vue';
import SvgPriori from 'src/vue-app/shared/components/svg-icons/svg-priori.vue';
import SvgPrioriPurple from 'src/vue-app/shared/components/svg-icons/svg-priori-purple.vue';
import SvgPriorityHigh from 'src/vue-app/shared/components/svg-icons/svg-priority-high.vue';
import SvgPriorityLow from 'src/vue-app/shared/components/svg-icons/svg-priority-low.vue';
import SvgPriorityMedium from 'src/vue-app/shared/components/svg-icons/svg-priority-medium.vue';
import SvgProfileData from 'src/vue-app/shared/components/svg-icons/svg-profile-data.vue';
import SvgProfile from 'src/vue-app/shared/components/svg-icons/svg-profile.vue';
import SvgProjects from 'src/vue-app/shared/components/svg-icons/svg-projects.vue';
import SvgQuestionCircle from 'src/vue-app/shared/components/svg-icons/svg-question-circle.vue';
import SvgRate from 'src/vue-app/shared/components/svg-icons/svg-rate.vue';
import SvgRedo from 'src/vue-app/shared/components/svg-icons/svg-redo.vue';
import SvgRefresh from 'src/vue-app/shared/components/svg-icons/svg-refresh.vue';
import SvgRehire from 'src/vue-app/shared/components/svg-icons/svg-rehire.vue';
import SvgReject from 'src/vue-app/shared/components/svg-icons/svg-reject.vue';
import SvgRequestGradient from 'src/vue-app/shared/components/svg-icons/svg-request-gradient.vue';
import SvgResume from 'src/vue-app/shared/components/svg-icons/svg-resume.vue';
import SvgRfq from 'src/vue-app/shared/components/svg-icons/svg-rfq.vue';
import SvgRfqLogo from 'src/vue-app/shared/components/svg-icons/svg-rfq-logo.vue';
import SvgRibbon from 'src/vue-app/shared/components/svg-icons/svg-ribbon.vue';
import SvgScoutLogoBeta from 'src/vue-app/shared/components/svg-icons/svg-scout-logo-beta.vue';
import SvgScoutLogo from 'src/vue-app/shared/components/svg-icons/svg-scout-logo.vue';
import SvgSearch from 'src/vue-app/shared/components/svg-icons/svg-search.vue';
import SvgStarDollar from 'src/vue-app/shared/components/svg-icons/svg-star-dollar.vue';
import SvgStrikethrough from 'src/vue-app/shared/components/svg-icons/svg-strikethrough.vue';
import SvgFormUpload from 'src/vue-app/shared/components/svg-icons/svg-form-upload.vue';

// TODO: Fix this SVG.
// import SvgSettingsScreen from 'src/vue-app/shared/components/svg-icons/svg-settings-screen.vue';
import SvgSettings from 'src/vue-app/shared/components/svg-icons/svg-settings.vue';
import SvgSidebarNewProject from 'vue-app/shared/components/svg-icons/svg-sidebar-new-project.vue';
import SvgSidenavContactPriori from 'src/vue-app/shared/components/svg-icons/svg-sidenav-contact-priori.vue';
import SvgSidenavDocuments from 'src/vue-app/shared/components/svg-icons/svg-sidenav-documents.vue';
import SvgSidenavHelpGuide from 'src/vue-app/shared/components/svg-icons/svg-sidenav-help-guide.vue';
import SvgSidenavHome from 'src/vue-app/shared/components/svg-icons/svg-sidenav-home.vue';
import SvgSidenavInvoices from 'src/vue-app/shared/components/svg-icons/svg-sidenav-invoices.vue';
import SvgSidenavMarketplaceLogoWhite from 'src/vue-app/shared/components/svg-icons/svg-sidenav-marketplace-logo-white.vue';
import SvgSidenavProjects from 'src/vue-app/shared/components/svg-icons/svg-sidenav-projects.vue';
import SvgSignOut from 'src/vue-app/shared/components/svg-icons/svg-sign-out.vue';
import SvgStarFilled from 'src/vue-app/shared/components/svg-icons/svg-star-filled.vue';
import SvgStar from 'src/vue-app/shared/components/svg-icons/svg-star.vue';
import SvgSwitch from 'src/vue-app/shared/components/svg-icons/svg-switch.vue';
import SvgTextAlignCenter from 'src/vue-app/shared/components/svg-icons/svg-text-align-center.vue';
import SvgTextAlignLeft from 'src/vue-app/shared/components/svg-icons/svg-text-align-left.vue';
import SvgTextAlignRight from 'src/vue-app/shared/components/svg-icons/svg-text-align-right.vue';
import SvgTextBold from 'src/vue-app/shared/components/svg-icons/svg-text-bold.vue';
import SvgTextHeading from 'src/vue-app/shared/components/svg-icons/svg-text-heading.vue';
import SvgTextItalic from 'src/vue-app/shared/components/svg-icons/svg-text-italic.vue';
import SvgTextQuote from 'src/vue-app/shared/components/svg-icons/svg-text-quote.vue';
import SvgTextUnderline from 'src/vue-app/shared/components/svg-icons/svg-text-underline.vue';
import SvgThumbsDown from 'vue-app/shared/components/svg-icons/svg-thumbs-down.vue';
import SvgThumbsUp from 'vue-app/shared/components/svg-icons/svg-thumbs-up.vue';
import SvgTrash from 'src/vue-app/shared/components/svg-icons/svg-trash.vue';
import SvgTrophy from 'src/vue-app/shared/components/svg-icons/svg-trophy.vue';
import SvgTwitterX from 'src/vue-app/shared/components/svg-icons/svg-twitter-x.vue';
import SvgUndo from 'src/vue-app/shared/components/svg-icons/svg-undo.vue';
import SvgUpload from 'src/vue-app/shared/components/svg-icons/svg-upload.vue';
import SvgVideoCall from 'vue-app/shared/components/svg-icons/svg-video-call.vue';
import SvgVideo from 'src/vue-app/shared/components/svg-icons/svg-video.vue';
import SvgWarning from 'src/vue-app/shared/components/svg-icons/svg-warning.vue';
import SvgWifi from 'src/vue-app/shared/components/svg-icons/svg-wifi.vue';
import SvgWriting from 'src/vue-app/shared/components/svg-icons/svg-writing.vue';
import SvgXCircle from 'src/vue-app/shared/components/svg-icons/svg-x-circle.vue';
import SvgX from 'src/vue-app/shared/components/svg-icons/svg-x.vue';
import SvgX3 from 'src/vue-app/shared/components/svg-icons/svg-x3.vue';
import SvgX4 from 'src/vue-app/shared/components/svg-icons/svg-x4.vue';
import SvgMeshGlobe from 'src/vue-app/shared/components/svg-icons/svg-mesh-globe.vue';
import SvgFilters from 'src/vue-app/shared/components/svg-icons/svg-filters.vue';

export default {
  name: 'SvgIcon',

  components: {
    SvgAccountSettings,
    SvgAddInvitee,
    SvgAddLink,
    SvgAddItem,
    SvgAddedItemGradient,
    SvgAddedItem,
    SvgAlert,
    SvgAnalytics,
    SvgArchive,
    SvgArrowDown,
    SvgArrowUp,
    SvgArrowLeft,
    SvgArrowRight,
    SvgAskLawyer,
    SvgAttachment,
    SvgAward,
    SvgBriefcase,
    SvgCalculatorCheck,
    SvgCalendarCheck,
    SvgCalendarGradient,
    SvgCalendar,
    SvgCaretDown,
    SvgCaretLeft,
    SvgCaretRight,
    SvgCaretUp,
    SvgChat,
    SvgCheck,
    SvgCheckAndDollar,
    SvgCheckCircle,
    SvgCheckSmall,
    SvgCheckmarkGreen,
    SvgCheckmarkNoCircle,
    SvgCheckmarkWhite,
    SvgCheckmark,
    SvgCircle,
    SvgClients,
    SvgClockDue,
    SvgCopy,
    SvgCourt,
    SvgDefaultProfile,
    SvgDevices,
    SvgDocument,
    SvgDocuments,
    SvgDollar,
    SvgDownload,
    SvgDragAndDrop,
    SvgEducation,
    SvgEnvelope,
    SvgEnvelopeAlt,
    SvgExclamationWhite,
    SvgExclamation,
    SvgExpand,
    SvgExperience,
    SvgFeatureLabLogo,
    SvgFeedback,
    SvgFileUpArrow,
    SvgFilters,
    SvgFirmProfile,
    SvgFlyingStar,
    SvgFormDollar,
    SvgFormRadioButton,
    SvgFormText,
    SvgFormUpload,
    SvgGear,
    SvgGlobe,
    SvgGoogleDrive,
    SvgHeadquarters,
    SvgHome,
    SvgIdCard,
    SvgInfo,
    SvgInviteGradient,
    SvgInvite,
    SvgLanguage,
    SvgLawFirm,
    SvgLawyerProfile,
    SvgLinkedin,
    SvgListBullets,
    SvgList,
    SvgLocation,
    SvgLockedDevices,
    SvgLogOut,
    SvgMarketplaceLogo,
    SvgMenu,
    SvgMeshGlobe,
    SvgMessage,
    SvgMinimize,
    SvgMinusCircle,
    SvgMinus,
    SvgNewProject,
    SvgNote2,
    SvgNote,
    SvgNoteWithDollarSign,
    SvgNoteWithHashtag,
    SvgNotification,
    SvgOptions,
    SvgOrderedList,
    SvgPaperPlane,
    SvgPencil,
    SvgPhone,
    SvgPlusCircle,
    SvgPlus,
    SvgPresent,
    SvgPrint,
    SvgPrioriAi,
    SvgPrioriGradient,
    SvgPrioriLogo,
    SvgPriori,
    SvgPrioriPurple,
    SvgPriorityHigh,
    SvgPriorityLow,
    SvgPriorityMedium,
    SvgProfileData,
    SvgProfile,
    SvgProjects,
    SvgQuestionCircle,
    SvgRate,
    SvgRedo,
    SvgRefresh,
    SvgRehire,
    SvgReject,
    SvgRequestGradient,
    SvgResume,
    SvgRfq,
    SvgRfqLogo,
    SvgRibbon,
    SvgScoutLogoBeta,
    SvgScoutLogo,
    SvgSearch,
    // SvgSettingsScreen,
    SvgSettings,
    SvgSidebarNewProject,
    SvgSidenavContactPriori,
    SvgSidenavDocuments,
    SvgSidenavHelpGuide,
    SvgSidenavHome,
    SvgSidenavInvoices,
    SvgSidenavMarketplaceLogoWhite,
    SvgSidenavProjects,
    SvgSignOut,
    SvgStarFilled,
    SvgStar,
    SvgStarDollar,
    SvgStrikethrough,
    SvgSwitch,
    SvgTextAlignCenter,
    SvgTextAlignLeft,
    SvgTextAlignRight,
    SvgTextBold,
    SvgTextHeading,
    SvgTextItalic,
    SvgTextQuote,
    SvgTextUnderline,
    SvgThumbsDown,
    SvgThumbsUp,
    SvgTrash,
    SvgTrophy,
    SvgTwitterX,
    SvgUndo,
    SvgUpload,
    SvgVideoCall,
    SvgVideo,
    SvgWarning,
    SvgWifi,
    SvgWriting,
    SvgXCircle,
    SvgX,
    SvgX3,
    SvgX4
  },

  props: {
    name: {
      type: String,
      required: true
    }
  },

  computed: {
    componentName() {
      return 'svg-' + this.name;
    }
  }
};
</script>
